var lightboxOpen = false;
$(document).ready(function() {

	if(isIE()) {
		recalcSvgHeight();
	}

	if(window.innerWidth > 768) {
		//Fenster (desktop)
		fixOriginPoints(".fenster-single, .animateable-sign","fenster-single-animateable",null,null,true);
		fixOriginPoints("#toni-head","toni-head-animateable",null, null, false, {
			height:	114.2605972290039,
			width: 120.63854217529297,
			x: 0.5830217599868774,
			y: 0.0020532608032226562
		});
		fixOriginPoints("#bga","schnuppe-animateable", null, null, false, {
			height: 25.650779724121094,
			width: 71.5999755859375,
			x: 1065.2000732421875,
			y: 70.24922943115234
		});
		fixOriginPoints("#bgf","moon-animateable", null, null, false, {
			height:	40.20000076293945,
			width: 40.20000076293945,
			x: 267,
			y: 126.9000015258789
		});
		fixOriginPoints("#im","eiszapfen-3-animateable",null,null,false, {
			height: 26.949996948242188,
			width: 6.5,
			x: 27.799999237060547,
			y: 111
		});
		fixOriginPoints("#if","eiszapfen-1-animateable", null, null, false, {
			height: 20.649993896484375,
			width: 5.500030517578125,
			x: 373.1999816894531,
			y: 111
		});
		fixOriginPoints("#man-arm","mann-arm-animateable",62,48, false, {
			height: 65.81829833984375,
			width: 70.39996337890625,
			x: 684.3999633789062,
			y: 744.981689453125
		});
		fixOriginPoints("#hi","santa-animateable", null, null, false, {
			height: 42.95538330078125,
			width: 54.19427490234375,
			x: 182,
			y: 105.89460754394531
		});
		fixOriginPoints("#hm","rehntier1-animateable", null, null, false, {
			height: 62.300010681152344,
			width:43.05450439453125,
			x:7.500001907348633,
			y:0.29998457431793213
		});
		fixOriginPoints("#hq","rehntier2-animateable", null, null, false, {
			height:	59.5,
			width: 42.09849548339844,
			x: 69.33634948730469,
			y: 15.300002098083496
		});
		fixOriginPoints("#hu","rehntier3-animateable", null, null, false, {
			height: 59.648311614990234,
			width: 51.75727844238281,
			x: 130.68614196777344,
			y: 44.1999626159668
		});
		fixOriginPoints("#hy","santa-rope-animateable",null,null, false, {
			height:72.01447296142578,
			width:183.89999389648438,
			x:21.5,
			y:37.08551788330078
		});
	}
	else {
		//Fenster (mobil)
		fixOriginPoints(".fenster-single--mobile, .animateable-sign","fenster-single-animateable");
	}

	$(window).on("resize", function() {
		if(window.innerWidth > 768) {
			fixOriginPoints(".fenster-single","fenster-single-animateable");
		}
		if(isIE()) {
			recalcSvgHeight();
		}
	});

	setDoorClickEvent();

	setLightBoxDefaults();

	if(!isMobile()) {
		initSnowFlakes();
		$.fn.snow();
	}

	$("#rect-external-link").on("click", function(e) {
		var _this = $(this);
		var _link = _this.attr("data-link");
		console.log(_link);
		if(_link) {
			window.open(_link, '_blank').focus();
		}
	});
	$("#rect-bd-link").on("click", function(e) {
		var _this = $(this);
		var _link = _this.attr("data-link");
		if(_link) {
			window.open(_link, '_blank').focus();
		}
	});

});

function isIE() {
	var ua = window.navigator.userAgent;
	var msie = ua.indexOf("MSIE ");

	if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer, return version number
	{
		return true;
	}
	return false;
}


function recalcSvgHeight() {
	var wrapperWidth = $("#promo-adventskalender").eq(0).width();
	var calcedHeight = (1478.53 / 1179.93) * wrapperWidth;
	$("#adventkalender-svg").eq(0).css({
		width: wrapperWidth,
		height: calcedHeight
	})
}

function setDoorClickEvent() {
	var fenster = $(".fenster-single--current-day");
	fenster.on("click", function(e) {
		e.preventDefault();
		if (!lightboxOpen) {
			lightboxOpen = true;
			$.featherlight($("#lightbox-form").eq(0));
			initFormValidation();
		}
	})
}

function resetBodyHeight() {
	console.log("resetBodyHeight");
	$("body").css({
		"min-height":"0px",
		"overflow":"auto"
	});
}
function setBodyHeight() {
	console.log("setBodyHeight");
	var lightboxHeight = $(".lightbox-container .featherlight-content").outerHeight(true);
	$("body").css({
		"min-height": lightboxHeight+"px",
		"overflow": "visible"
	});
}

function clearRunningLight() {
	if(window.runningLightInterval) {
		clearInterval(window.runningLightInterval);
	}
	if(window.runningLightTimeout) {
		clearTimeout(window.runningLightInterval)
	}
}

function featherlightFadeIn(_this) {
	$(_this.$instance).addClass("featherlight-visible")
}
var google_widget;
function setLightBoxDefaults() {
    $.featherlight.defaults.beforeOpen = function() {
        //$('body').css('overflow', 'hidden');
    };

    $.featherlight.defaults.afterOpen = function(event) {
        var captcha = $(".featherlight-content .g-recaptcha");
	    if(this.target) {
		    if (captcha.length == 1 && this.target.hasClass('lightbox-form')) {
		        var form = captcha.closest('form');
		        google_widget = grecaptcha.render(captcha.attr("id"), {
	                'sitekey': '6LfB-QYjAAAAAPl1BbnAiX5tZK4MTjWkHWVOVAhp',
			        'size': 'invisible',
			        'callback': function(token) {
				        form.submit()
			        }
	            });
	        }
	    }
	    featherlightFadeIn(this);
	    setBodyHeight();
    };

    $.featherlight.defaults.beforeClose = function() {
        var agb = $(".featherlight-content #lightbox-agb");
        if (agb.length == 0) {
            $('body').css('overflow', 'auto');
            lightboxOpen = false;
        }
	    clearRunningLight();
        resetBodyHeight();
    };

    $.featherlight.defaults.root = '.lightbox-container';
    $.featherlight.defaults.closeIcon = '';
}


function initFormValidation() {

    var registrationform = $(".featherlight-content .registration-form");

    //Für Form Validate
    window.jbsValidateForm = registrationform.validate({
        submitHandler: function(form) {
            if (grecaptcha.getResponse(google_widget) === "") {
	            grecaptcha.execute(google_widget);
	            return false;
            } else {
                ajaxRequest();
            }
        },
        errorPlacement: function(error, element) {
          error.appendTo(element[0].parentNode);
        },
        errorClass: "error",
        errorElement: "label",
        messages: {
            "anrede": "Bitte w&auml;hlen Sie eine Anrede.",
            "vname": "Bitte geben Sie Ihren Vornamen ein.",
            "nname": "Bitte geben Sie Ihren Nachnamen ein.",
            "email": "Bitte geben Sie Ihre E-Mail Adresse ein.",
            "phone": "Bitte geben Sie eine gültige Telefon-Nummer ein.",
            "agb-accept": "Bitte best&auml;tigen Sie die Bedingungen.",
            "data-protection": "Bitte nehmen Sie die Datenschutzerkl&auml;rung zur Kenntnis.",
            "plz": "Bitte geben Sie eine g&uuml;ltige PLZ ein.",
            "ort": "Bitte geben Sie einen g&uuml;ltigen Ort ein.",
            "strasse": "Bitte geben Sie eine g&uuml;ltige Straße ein.",
            "strasse-nbr": "Bitte geben Sie eine g&uuml;ltige Hausnummer ein."
        }
    });


    $.validator.addMethod("pattern", function(value, element, param) {
        if (this.optional(element)) {
            return true;
        }
        if (typeof param === "string") {
            param = new RegExp("^(?:" + param + ")$");
        }
        return param.test(value);
    }, "Invalid format.");

    $.validator.addMethod("cRequired", $.validator.methods.required);

    $.validator.addClassRules("jubelschreie-required", {
        cRequired: true
    });

    registrationform.on("submit", function(e) {
        e.preventDefault();
    });

}

function ajaxRequest() {
    var form = $(".featherlight-content .registration-form");

    var agb = 0;
    var dataProtection = 0;
    if (form.find("input[name='agb-accept']").is(':checked')) {
        agb = 1;
    }
	if (form.find("input[name='data-protection']").is(':checked')) {
		dataProtection = 1;
	}
	var _newsletter = "";
	if (form.find("input[name='newsletter']").is(':checked')) {
		_newsletter = form.find("input[name='newsletter'] ~ span").text();
	}

	var torNr = form.attr("data-tag-nr");
    //Form Felder als object
    var formData = {
        "title": form.find("select[name='anrede']").val(),
        "firstname": form.find("input[name='vname']").val(),
        "lastname": form.find("input[name='nname']").val(),
        "email": form.find("input[name='email']").val(),
        "street": form.find("input[name='strasse']").val(),
        "streetnumber": form.find("input[name='strasse-nbr']").val(),
        "zip": form.find("input[name='plz']").val(),
        "city": form.find("input[name='ort']").val(),
		"door": torNr,
	    "newsletter": _newsletter,
        "teilnahme": agb,
        "datenschutz": dataProtection,
        "phone": form.find("input[name='phone']").val()
    };

    //Ajax Form POST
	formData.grepcaptcha = grecaptcha.getResponse(google_widget);



	try {
	    $.ajax({
	            type: 'POST',
	            beforeSend: function(request) {
				  request.setRequestHeader("pd-api-key", "pd002#B154kn*5p!");
				},
	            url: 'https://mt.secured-api.bruns.dev/subscribe',
	            data: JSON.stringify(formData), //Zu Sendene Daten
	            dataType: 'json', //Format der Antwort
	            processData: false,
	            contentType: "application/json",
	            encode: false
	        })
	        .done(function(data) {
		        if(data === "Done") {
	                $.featherlight.current().close();
			        $.featherlight($('#lightbox-danke'));

	            } else {
			        $.featherlight.current().close();
	                $.featherlight($('#lightbox-error'));
	            }
	        }).fail(function(data) {
		        $.featherlight.current().close();
			    $.featherlight($('#lightbox-error'));
	    });
	}
	catch(e) {
		$.featherlight($('#lightbox-error'));
	}
}

function initSnowFlakes() {
	/**
	 * jquery.snow - jQuery Snow Effect Plugin
	 *
	 * Available under MIT licence
	 *
	 * @version 1 (21. Jan 2012)
	 * @author Ivan Lazarevic
	 * @requires jQuery
	 * @see http://workshop.rs
	 *
	 * @params flakeChar - the HTML char to animate
	 * @params minSize - min size of snowflake, 10 by default
	 * @params maxSize - max size of snowflake, 20 by default
	 * @params newOn - frequency in ms of appearing of new snowflake, 500 by default
	 * @params flakeColors - array of colors , #FFFFFF by default
	 * @params durationMillis - stop effect after duration
	 * @example $.fn.snow({ maxSize: 200, newOn: 1000 });
	 */
	$.fn.snow = function(options){

		var $flake 			= $('<div class="flake" />').css({'position': 'absolute', 'top': '-50px'}),
			documentHeight 	= $('#flake-wrapper').height(),                                                 //Edit BM
			documentWidth	= $('#flake-wrapper').width(),                                                  //Edit BM
			defaults		= {
				flakeChar	: "&#8226;",
				minSize		: 10,
				maxSize		: 25,
				newOn		: 100,
				flakeColor	: ["#ffffff"],
				durationMillis: null
			},
			options			= $.extend({}, defaults, options);

		$flake.html(options.flakeChar);

		var interval		= setInterval( function(){
			var startPositionLeft 	= Math.random() * documentWidth - 100,
				startOpacity		= 0.5 + Math.random(),
				sizeFlake			= options.minSize + Math.random() * options.maxSize,
				endPositionTop		= documentHeight - defaults.maxSize - 40,
				endPositionLeft		= startPositionLeft - 100 + Math.random() * 200,
				durationFall		= documentHeight * 7 + Math.random() * 5000;
			$flake
				.clone()
				.appendTo('#flake-wrapper')                                                                 //Edit BM
				.css(
				{
					left: startPositionLeft,
					opacity: startOpacity,
					'font-size': sizeFlake,
					color: options.flakeColor[Math.floor((Math.random() * options.flakeColor.length))]
				}
			)
				.animate(
				{
					top: endPositionTop,
					left: endPositionLeft,
					opacity: 0.0
				},
				durationFall,
				'linear',
				function() {
					$(this).remove()
				}
			);
		}, options.newOn);

		if (options.durationMillis) {
			setTimeout(function() {
				removeInterval(interval);
			}, options.durationMillis);
		}
	};
}

function isMobile() {
	if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
		return true;
	}
	return false;
}

function fixOriginPoints(selector, additionalClass, xPos, yPos, keepTransformTranslate, fakeBBox) {
	var elements = $(selector);
	for(var i = 0; i < elements.length; i++) {
		var bBox = fakeBBox || elements.eq(i).get(0).getBBox();
		var fX,fY;

		if(xPos == undefined || xPos == null) { fX = bBox.x + (0.5 * bBox.width); }
		else { fX = bBox.x + xPos; }

		if(yPos == undefined || yPos == null) {	fY = bBox.y + (0.5 * bBox.height); }
		else { fY = bBox.y + yPos; }

		elements[i].style.transformOrigin = fX + "px " +  fY + "px";
		if(keepTransformTranslate) {
			var transform = $(elements[i]).attr("transform");
			if(transform && transform.indexOf("translate") !== -1) {
				var translate = transform.split("translate(")[1].split(")")[0].split(" ");
				elements[i].style.transform = "translate("+translate[0]+"px, "+translate[1]+"px)";
			}

		}
		if(additionalClass) {
			if(elements[i].classList) {
				elements[i].classList.add(additionalClass);
			}
		}
	}
}
